<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Consider the standard reduction potentials for the two reactions shown below:
      </p>

      <v-simple-table style="max-width: 350px" class="mb-4 pl-6">
        <tbody>
          <tr>
            <td>
              <chemical-latex content="Cr^3+ + 3e- -> Cr" />
            </td>
            <td>
              <stemble-latex content="$\text{E}^\circ=-0.073\,\text{V}$" />
            </td>
          </tr>
          <tr>
            <td>
              <chemical-latex content="Br2 + 2e- -> 2Br-" />
            </td>
            <td>
              <stemble-latex content="$\text{E}^\circ=+1.09\,\text{V}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) What is the balanced chemical equation for the galvanic cell? No states are required.
      </p>

      <chemical-notation-input
        v-model="inputs.cellReaction"
        :show-note="false"
        class="mb-5"
        :disabled="!allowEditing"
        disable-validation
      />

      <p class="mb-2">
        b) When these two half-reactions form a galvanic cell, what is the standard cell potential
        in V?
      </p>

      <calculation-input
        v-model="inputs.Ecell"
        class="mb-0"
        prepend-text="$\text{E}^\circ_\text{cell}:$"
        append-text="$\text{V}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default {
  name: 'Question507',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        cellReaction: null,
        Ecell: null,
      },
    };
  },
};
</script>
